'use client';

import { getImageUrl } from './imageUrl';

export default function loadImage({
  src,
  width,
}: {
  src: string;
  width: number;
  quality?: number;
}) {
  const imageUrl = getImageUrl(src, width * 1.5);

  return imageUrl;
}
